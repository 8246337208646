import { getLanguageFile } from "./langauge.helper"

export const arrayChunk = (arr, n) => {
  const array = arr.slice()
  const chunks = []
  while (array.length) chunks.push(array.splice(0, n))
  return chunks
}

export const getJobs = state => {
  return [
    {
      cardName: "gameDeveloper_001",
      title: getLanguageFile(state.language).Career.gameApplication,
      description: getLanguageFile(state.language).Career
        .gameApplicationDescription,
      disabled: false,
    },
    {
      cardName: "electricalandElectronicsEngineer",
      title: getLanguageFile(state.language).Career.eeApplication,
      description: getLanguageFile(state.language).Career
        .eeApplicationDescription,
      disabled: false,
    },
    {
      cardName: "softwareEngineer_006",
      title: getLanguageFile(state.language).Career.bdiApplication,
      description: getLanguageFile(state.language).Career
        .bdiApplicationDescription,
      disabled: false,
    },
    {
      cardName: "sysadmin_001",
      title: getLanguageFile(state.language).Career.saApplication,
      description: getLanguageFile(state.language).Career
        .saApplicationDescription,
      disabled: false,
    },
    {
      cardName: "softwareEngineer_005",
      title: getLanguageFile(state.language).Career.hmiApplication2,
      description: getLanguageFile(state.language).Career
        .hmiApplicationDescription2,
      disabled: false,
    },
    {
      cardName: "softwareEngineer_004",
      title: getLanguageFile(state.language).Career.nlpApplication,
      description: getLanguageFile(state.language).Career
        .nlpApplicationDescription,
      disabled: false,
    },
    {
      cardName: "softwareEngineer_003",
      title: getLanguageFile(state.language).Career.hmiApplication,
      description: getLanguageFile(state.language).Career
        .hmiApplicationDescription,
      disabled: false,
    },
    {
      cardName: "softwareEngineer_002",
      title: getLanguageFile(state.language).Career.msApplication,
      description: getLanguageFile(state.language).Career
        .msApplicationDescription,
      disabled: false,
    },
    {
      cardName: "softwareEngineer_001",
      title: getLanguageFile(state.language).Career.softwareEngineer,
      description: getLanguageFile(state.language).Career
        .softwareEngineerDescription,
      disabled: false,
    },
    {
      cardName: "intern",
      title: getLanguageFile(state.language).Career.internJobDescription,
      description: getLanguageFile(state.language).Career.internDescription,
      disabled: false,
    },
    {
      cardName: "partTime",
      title: getLanguageFile(state.language).Career.partTimeJobDescription,
      description: getLanguageFile(state.language).Career.partTimeDescription,
      disabled: false,
    },
  ]
}
