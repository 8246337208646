import React, { useState } from "react"
import { Container, Row, Col, Image, Button } from "react-bootstrap"
import { Helmet } from "react-helmet"
import Slider from "react-slick"
import { useMediaQuery } from "react-responsive"

import Header from "../../components/Header"
import JobCard from "../../components/JobCard"

import Footer from "../../components/Footer"

import { useGlobalState } from "../../global/global"
import { getLanguageFile } from "../../global/langauge.helper"
import { getJobs, arrayChunk } from "../../global/careerConfig"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import CUBES from "../../../assets/images/careerDetailCubes.png"

import "./styles.scss"

const CareerPage = () => {
  const [state] = useGlobalState()
  const [isViewAll, setIsViewAll] = useState(false)
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  })
  const sliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    slidesPerRow: 1,
    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const onViewAll = () => {
    setIsViewAll(!isViewAll)
  }

  return (
    <Container fluid className="main-container">
      <div className="application">
        <Helmet title={getLanguageFile(state.language).Title.career}>
          <meta charSet="utf-8" />
        </Helmet>
      </div>
      <Header headerTextMode="dark" />
      <Container fluid className="career-image-container">
        <Image className={"career-image-background"} src={CUBES} />
      </Container>
      <Container
        fluid
        className="career-first-section d-flex align-items-center"
      >
        <Container className="career-introduction-container">
          <Row>
            <Col className={"career-introduction-texts-col col-lg-8 col-10"}>
              <Row>
                <h2 className="career-introduction-title">
                  {getLanguageFile(state.language).Career.introductionTitle}
                </h2>
              </Row>
              <Row>
                <p className="career-introduction-description">
                  {
                    getLanguageFile(state.language).Career
                      .introductionDescriptionOne
                  }
                </p>
                <p className="career-introduction-description">
                  {
                    getLanguageFile(state.language).Career
                      .introductionDescriptionTwo
                  }
                </p>
                <p className="career-introduction-description">
                  {
                    getLanguageFile(state.language).Career
                      .introductionDescriptionThree
                  }
                </p>
                <p className="career-introduction-description">
                  {
                    getLanguageFile(state.language).Career
                      .introductionDescriptionFour
                  }
                </p>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container className="second-title-container">
        <h2 className="second-title">
          {getLanguageFile(state.language).Career.secondTitle}
        </h2>
      </Container>
      <Container className="job-card-row-container">
        {isViewAll ? (
          <>
            {arrayChunk(
              getJobs(state).filter(o => !o.disabled),
              3
            ).map((row, i) => (
              <div key={i} className="row mx-auto">
                {row.map(o => (
                  <JobCard
                    key={o.cardName}
                    extraStyle={{
                      width: 280,
                    }}
                    className="job-card"
                    cardName={o.cardName}
                    cardTitle={o.title}
                    cardDescription={o.description}
                    cardButtonName={
                      getLanguageFile(state.language).Career.detailsButton
                    }
                  />
                ))}
              </div>
            ))}
          </>
        ) : (
          <Slider style={{ width: "100%" }} {...sliderSettings}>
            {getJobs(state)
              .filter(o => !o.disabled)
              .map(o => (
                <JobCard
                  key={o.cardName}
                  className="job-card"
                  cardName={o.cardName}
                  cardTitle={o.title}
                  cardDescription={o.description}
                  cardButtonName={
                    getLanguageFile(state.language).Career.detailsButton
                  }
                />
              ))}
          </Slider>
        )}

        {isDesktopOrLaptop && (
          <div className="view-all-container">
            <Button className="btn-primary-career" onClick={onViewAll}>
              {isViewAll
                ? getLanguageFile(state.language).Career.seeLess
                : getLanguageFile(state.language).Career.viewAll}
            </Button>
          </div>
        )}
      </Container>
      <Footer />
    </Container>
  )
}

export default CareerPage
